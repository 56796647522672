import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import Sidebar from "../components/Sidebar"
import { InteriorContent } from "../components/InteriorContent"
import Img from "gatsby-image"
import underline from "../images/underline.png"
import WhatOurClientsAreSaying from "../components/WhatOurClientsAreSaying"
import Maps from "../components/Maps"
import {
  ContentSidebarContainerNoFeaturedImage,
  LineBottom,
  HeaderSection,
} from "../components/PageElements"
import favicon from "../images/favicon.png"

const LibraryitemTemplate = ({ data, pageContext }) => {
  const libraryitempage = data.wpgraphql.libraryitem

  const breakpoints = useBreakpoint()

  const {
    title,
    metaDesc,
    opengraphTitle,
    twitterDescription,
    twitterTitle,
  } = pageContext.seo
  return (
    <Layout>
      <Helmet>
        <link rel="icon" href={favicon} />
        <title>{opengraphTitle ? opengraphTitle : title}</title>
        <meta name="description" content={metaDesc ? metaDesc : ""} />
        {pageContext.seo.opengraphImage !== null && (
          <meta
            name="image"
            content={pageContext.seo.opengraphImage.mediaItemUrl}
          />
        )}
        <meta
          property="og:url"
          content={`https://www.mmklawfirm.com/${pageContext.slug}`}
        />
        <meta
          property="og:title"
          content={opengraphTitle ? opengraphTitle : title}
        />
        <meta property="og:description" content={metaDesc ? metaDesc : ""} />
        {pageContext.seo.opengraphImage !== null && (
          <meta
            property="og:image"
            content={pageContext.seo.opengraphImage.mediaItemUrl}
          />
        )}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content={`https://www.mmklawfirm.com/${pageContext.slug}`}
        />
        <meta
          name="twitter:title"
          content={
            twitterTitle
              ? twitterTitle
              : opengraphTitle
              ? opengraphTitle
              : title
          }
        />
        <meta
          name="twitter:description"
          content={
            twitterDescription ? twitterDescription : metaDesc ? metaDesc : ""
          }
        />
        {pageContext.seo.opengraphImage != null ||
          (pageContext.seo.twitterImage != null && (
            <meta
              name="twitter:image"
              content={
                pageContext.seo.twitterImage
                  ? pageContext.seo.twitterImage.mediaItemUrl
                  : pageContext.seo.opengraphImage
                  ? pageContext.seo.opengraphImage.mediaItemUrl
                  : ""
              }
            />
          ))}
      </Helmet>
      <ContentSidebarContainerNoFeaturedImage>
        <InteriorContent>
          <HeaderSection>
            <h3>{libraryitempage.title}</h3>
            <h1>JENKINTOWN PERSONAL INJURY ATTORNEYS</h1>
            <LineBottom>
              {breakpoints.sm ? (
                <>
                  <Img fluid={data.file.childImageSharp.fluid} />
                </>
              ) : (
                <img className="underline" src={underline} />
              )}
            </LineBottom>
          </HeaderSection>
          <div dangerouslySetInnerHTML={{ __html: libraryitempage.content }} />
        </InteriorContent>
        <Sidebar />
      </ContentSidebarContainerNoFeaturedImage>
      <WhatOurClientsAreSaying />
      <Maps />
    </Layout>
  )
}

export default LibraryitemTemplate

export const query = graphql`
  query($id: ID!) {
    wpgraphql {
      libraryitem(id: $id) {
        title
        content
      }
    }
    file(relativePath: { eq: "underline.png" }) {
      childImageSharp {
        fluid(maxWidth: 501, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
        fixed(width: 660, height: 18, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`
